import React, { useEffect } from 'react';
import { 
    BrowserRouter,
    Switch,
    Route,
    useLocation,
    withRouter } from 'react-router-dom';
import Helmet from "react-helmet";

import { MainNavigation } from "./components/MainNavigation.js";
import { Logo } from "./components/Logo.js";
import { Footer } from "./components/Footer.js";

import Home from "./pages/Home.js";
import Contact from "./pages/Contact.js";
import Skills from "./pages/Skills.js";
import Clients from "./pages/Clients.js";
import PrivacyPolicy from "./pages/PrivacyPolicy.js";
import CookiePolicy from "./pages/CookiePolicy.js";

import './App.scss';


function _ScrollToTop(props) {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);

        //console.log(pathname);

        //var el = 
        var prefix = "page-";
        var classes = document.body.className.split(" ").filter(function(c) {
            return c.lastIndexOf(prefix, 0) !== 0;
        });
        document.body.className = classes.join(" ").trim();

        const bodyClass = 'page-' + pathname.substring(1);
        console.log(bodyClass);
        document.body.classList.add(bodyClass);

        let menuWidth = document.getElementById('mainNavigationWrapper').offsetWidth;

        document.body.classList.remove("menu-open");
        document.getElementById('menu_toggle').className -= " active";
        document.getElementById('pageWrapper').style.transform = 'translateX(0px)';
        document.getElementById('logo').style.transform = 'translateX(0px)';
        document.getElementById('mainNavigationWrapper').style.transform = 'translateX(-' + menuWidth + 'px)';

        setTimeout(function(){
          document.getElementById('menu_toggle').style.transform = 'translate3d(0px, 0px, 0px)';
          setTimeout(function(){
            document.getElementById('menu_toggle').classList.remove("active");
          },0);
        },300);

        let navList = document.getElementById("links");
        let listItem = navList.getElementsByTagName("li");

        for (let i=0; i<listItem.length; i++) {
            listItem[i].classList.remove("active");
        }
        
        let socialList = document.getElementById("social");
        let socialListItem = socialList.getElementsByTagName("li");

        for (let i=0; i<socialListItem.length; i++) {
            socialListItem[i].classList.remove("active");
        }

    }, [pathname]);

    return props.children
}

const ScrollToTop = withRouter(_ScrollToTop)

function App() {

  return (
    
    <div className="App">

      <Helmet>
        <title>Christian Armstrong | Web Development, Ecommerce, Workflow</title>
        <meta name="description" content="" />
        <meta charset="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
        <meta name="author" content="Christian Armstrong" />
        <meta http-equiv='cache-control' content='no-cache' />
        <meta http-equiv='expires' content='0' />
        <meta http-equiv='pragma' content='no-cache' />
      </Helmet>
      
      <BrowserRouter>

        <MainNavigation />
        <Logo />

        <ScrollToTop>

        <div id="pageWrapper">
        <div id="main" className="main" role="main">

        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          <Route path="/contact">
            <Contact />
          </Route>

          <Route path="/skills">
            <Skills />
          </Route>

          <Route path="/clients">
            <Clients />
          </Route>

          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>

          <Route path="/cookie-policy">
            <CookiePolicy />
          </Route>

        </Switch>
        
        <Footer />

        </div>
        </div>


        </ScrollToTop>

      </BrowserRouter>

    </div>
  );
}

export default App;
