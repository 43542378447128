import React from 'react';
import Helmet from "react-helmet";
import { HomeIntro } from "../components/HomeIntro.js";
import { HomeIntroPerson } from "../components/HomeIntroPerson.js";
//import { HomeGrid } from "../components/HomeGrid.js";

function Home() {

  return (
    
    <div className="AppSection">

      <Helmet>
        <title>Christian Armstrong | Web Development, Ecommerce, Workflow</title>
        <meta name="description" content="" />
      </Helmet>

      <HomeIntro />
      <HomeIntroPerson />
      

    </div>
  );
}

export default Home;