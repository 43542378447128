import React from 'react';
import { Link } from 'react-router-dom';

export class HomeIntroPerson extends React.Component {
  
  render() {
    return (
      <div id="home_intro_person">
        <div id="home_intro_person_inner">
          <div id="home_intro_person_content">
            <div>Ecommerce / Digital Business</div>
            <div className="title_primary">Web Developer / UX Developer</div>
            <div className="title_contact">
              <Link to="/contact" className="contact_link">Contact Me</Link>
            </div>
            <div className="social_icons">
                  <div></div>
                  <div><a href="mailto:contact@christianarmstrong.co.uk" className="social-icon social-icon-email" target="_blank" rel="noopener noreferrer"><span>Email</span></a></div>
                  <div><a href="https://www.instagram.com/christianrhymes/" className="social-icon social-icon-instagram" target="_blank" rel="noopener noreferrer"><span>Instagram</span></a></div>
                  <div><a href="https://www.linkedin.com/in/chrisarmo/" className="social-icon social-icon-linkedin" target="_blank" rel="noopener noreferrer"><span>Linked In</span></a></div>
                  <div></div>
                </div>
          </div>
        </div>
      </div>
    );
  }
}

