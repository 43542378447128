import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';

export class Footer extends React.Component {
  render() {
    return (
      <footer id="footer">
        <ul>
          <li><Link to="/contact" className="contact_link">Contact</Link></li>
          <li><Link to="/privacy-policy">Privacy Policy</Link></li>
          <li><Link to="/cookie-policy">Cookie Policy</Link></li>
          
        </ul>

        <div className="social_icons">
          <div></div>
          <div><a href="mailto:contact@christianarmstrong.co.uk" className="social-icon social-icon-email" target="_blank" rel="noopener noreferrer"><span>Email</span></a></div>
          <div><a href="https://www.instagram.com/christianrhymes/" className="social-icon social-icon-instagram" target="_blank" rel="noopener noreferrer"><span>Instagram</span></a></div>
          <div><a href="https://www.linkedin.com/in/chrisarmo/" className="social-icon social-icon-linkedin" target="_blank" rel="noopener noreferrer"><span>Linked In</span></a></div>
          <div></div>
        </div>

      </footer>
    );
  }
}

