import React from 'react';

export class HomeIntro extends React.Component {
  
  componentDidMount() {
    setTimeout(function(){
      document.getElementById('home_intro_content').className += "active";
    }, 1500);
  }

  render() {
    return (
      <div id="home_intro">
        <div id="home_intro_inner">
          <div id="home_intro_content">
            Hi, I'm Christian,<br />
            a Front end Developer who focuses on<br />
            writing clean, elegant and efficient code..
          </div>
        </div>
      </div>
    );
  }
}

