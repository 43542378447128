import React from 'react';
import Helmet from "react-helmet";
import './Skills.scss';

function Skills() {

  return (
    
    <div className="AppSection">

		<Helmet>
			<title>Christian Armstrong | Skills</title>
			<meta name="description" content="" />
		</Helmet>

		<div className="SkillsContentPage">
			<article className="backpage">
				<h2>Skills</h2>
				
			</article>
		</div>
      

    </div>
  );
}

export default Skills;