import React from 'react';
import Helmet from "react-helmet";
import './Clients.scss';

function Clients() {

  return (
    
    <div className="AppSection">

		<Helmet>
			<title>Christian Armstrong | Clients</title>
			<meta name="description" content="" />
		</Helmet>

		<div className="ClientsContentPage">
			<article className="backpage">
				<h2>Clients</h2>
				
			</article>
		</div>
      

    </div>
  );
}

export default Clients;