import React from 'react';
import { Link } from 'react-router-dom';

export class Logo extends React.Component {
  
  render() {
    return (
      <Link to="/" id="logo">
        <h1>Christian Armstrong</h1>
        <h2>Front End Developer</h2>
      </Link>
    );
  }
}

