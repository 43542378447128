import React from 'react';
import { Link } from 'react-router-dom';
import './MainNavigation.scss';

export class MainNavigation extends React.Component {
   
  togglenav() {
    //alert('boom');
    let menuWidth = document.getElementById('mainNavigationWrapper').offsetWidth;

    if(document.getElementById('menu_toggle').classList.contains('active')){
      
      document.body.classList.remove("menu-open");
      document.getElementById('menu_toggle').className -= " active";
      document.getElementById('pageWrapper').style.transform = 'translateX(0px)';
      document.getElementById('logo').style.transform = 'translateX(0px)';
      document.getElementById('mainNavigationWrapper').style.transform = 'translateX(-' + menuWidth + 'px)';

      setTimeout(function(){
        document.getElementById('menu_toggle').style.transform = 'translate3d(0px, 0px, 0px)';
        setTimeout(function(){
          document.getElementById('menu_toggle').classList.remove("active");
        },0);
      },300);

      let navList = document.getElementById("links");
      let listItem = navList.getElementsByTagName("li");

      for (let i=0; i<listItem.length; i++) {
          listItem[i].classList.remove("active");
      }
      
      let socialList = document.getElementById("social");
      let socialListItem = socialList.getElementsByTagName("li");

      for (let i=0; i<socialListItem.length; i++) {
          socialListItem[i].classList.remove("active");
      }

      
    } else {
      var menutimer = 0;
      var menutimerInterval = 150;

      document.body.classList.add("menu-open");
      document.getElementById('pageWrapper').style.transform = 'translateX(' + menuWidth + 'px)';
      document.getElementById('logo').style.transform = 'translateX(' + menuWidth + 'px)';
      document.getElementById('mainNavigationWrapper').style.transform = 'translateX(0px)';

      var togglePos = document.getElementById('menu_toggle').offsetLeft;
      var newPos = -(togglePos) + menuWidth - 20 -30;

      setTimeout(function(){
        document.getElementById('menu_toggle').style.transform = 'translate3d(' + newPos + 'px, 0px, 0px)';
        setTimeout(function(){
          //$('#menu_toggle').addClass('active');
          document.getElementById('menu_toggle').classList.add("active");
        },300);
      },300);

      let navList = document.getElementById("links");
      let listItem = navList.getElementsByTagName("li");

      setTimeout(function(){
        for (let i=0; i<listItem.length; i++) {
            menutimer = menutimer + menutimerInterval;
            setTimeout(function(){
              listItem[i].classList.add("active");
            }, menutimer);
        }
      }, 500);
      
      let socialList = document.getElementById("social");
      let socialListItem = socialList.getElementsByTagName("li");

      setTimeout(function(){
        for (let i=0; i<socialListItem.length; i++) {
            menutimer = menutimer + menutimerInterval;
            setTimeout(function(){
              socialListItem[i].classList.add("active");
            }, menutimer);
        }
      }, 500);

    };

  }


  render() {
    
    return (
      <div id="mainNavigationWrapper">
        <div id="mainNavigationInner">
          <div id="menu_toggle" className="menu_toggle" onClick={this.togglenav}>
            <div id="menu_toggle_inner">
              <div id="menu_toggle_label">
                Menu
              </div>
            </div>
          </div>  
          <div id="mainNavigationScrollWrapper">
          <div id="mainNavigationScrollInner">
          <div id="mainNavigationLinks">
            <div id="mainNavigationLinksInner">
              <nav id="mainNavigation" role="navigation">
                <ul id="links">
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/skills">Skills</Link></li>
                  <li><Link to="/clients">Clients</Link></li>
                  <li><Link to="/contact" className="contact_link">Contact</Link></li>
                </ul>
                <ul id="social">
                  <li><a href="https://www.instagram.com/christianrhymes/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                  <li><a href="https://www.linkedin.com/in/chrisarmo/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                </ul>
              </nav>
            </div>
          </div>
          </div>
          </div>
        </div>
      </div>
    );
  }
}

