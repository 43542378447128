import React from 'react';
import Helmet from "react-helmet";
import './Contact.scss';

function Contact() {

  return (
    
    <div className="AppSection">

		<Helmet>
			<title>Christian Armstrong | Contact</title>
			<meta name="description" content="" />
		</Helmet>

		<div className="ContactContentPage">
			<article className="backpage">
				<h2>Contact</h2>
				
			</article>
		</div>
      

    </div>
  );
}

export default Contact;